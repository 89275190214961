const labels = {
    CPFTIT: 'CPF do Titular',
    DTNASCTIT: 'Data Nascimento Titular',
    NMTIT: 'Nome Completo do Titular',
    SEXOTIT: 'Sexo do Titular',
    NMMAETIT: 'Nome Completo da Mãe do Titular',
    CELULAR: 'Celular',
    ENVIOSMS: 'Autoriza envio de SMS?',
    EMAIL: 'E-mail',
    ENVIOEMAIL: 'Autoriza envio de e-mail?',
    IDFUNCIONAL: 'Nº da identificação Funcional',
    NUCLEO: 'Nº do núcleo',
    CIDADE: 'Nome da cidade de residência',
    CODAUTENTICIDADE: 'Cód. De Autenticidade',
    FORMAPAGTO: 'Forma de pagamento',
    NROPARCELAS: 'Nº de Parcelas',
    VLRPARCELA: 'Valor das parcelas',
    DTVCTOPARCELAS: 'Data de Vencimento da Parcela',
    CPFDEP: 'CPF do dependente',
    DTNASCDEP: 'Data de nascimento do dependente',
    NMDEP: 'Nome do dependente',
    NMMADDEP: 'Nome da mãe do dependente',
    PARENTESCODEP: 'Parentesco do dependente',
    SEXODEP: 'Sexo do dependente',
    CONTRACHEQUE: 'Anexar o último contracheque'
}

export { labels }