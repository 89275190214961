import { Form, Row, Col, Button, Divider, Alert } from "antd"
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import FieldSwitch from "../../../../../../components/fieldSwitch";
import Repeatable from "./components/repeatable";
import { useEffect, useState, useContext } from "react";
import { CpersContext } from '../../../../index'
import { differenceInYears, isDate, parse } from 'date-fns'

function parseDateString(value, originalValue) {
    const parsedDate = isDate(originalValue)
      ? originalValue
      : parse(originalValue, "dd/MM/yyyy", new Date());
  
    return parsedDate;
}

  
function TestaCPF(strCPF) {
    var Soma;
    var Resto;
    Soma = 0;   
    if (strCPF) {
        strCPF = strCPF.replace(/\D/g, "")
        if (strCPF == "00000000000")
        return false;
        for (i=1; i<=9; i++)
        Soma = Soma + parseInt(strCPF.substring(i-1, i)) * (11 - i); 
        Resto = (Soma * 10) % 11;
        if ((Resto == 10) || (Resto == 11)) 
        Resto = 0;
        if (Resto != parseInt(strCPF.substring(9, 10)) )
        return false;
        Soma = 0;
        for (var i = 1; i <= 10; i++)
        Soma = Soma + parseInt(strCPF.substring(i-1, i)) * (12 - i);
        Resto = (Soma * 10) % 11;
        if ((Resto == 10) || (Resto == 11)) 
        Resto = 0;
        if (Resto != parseInt(strCPF.substring(10, 11) ) )
            return false;
        return true;
    } else {
        return false
    }
}

const schema = yup.object({
    NMTIT: yup.string().required("Nome do titular é obrigatório").test(null,'Você precisa inserir o sobrenome', value => {
        const valueArr = value?.split(' ')
        return valueArr?.length > 1 && valueArr[1]?.length > 0 ? true : false
    }),
    CPFTIT: yup.string().required('CPF é obrigatório').test(null, 'CPF Inválido', value => {
        return TestaCPF(value)
    }),
    DTNASCTIT: yup.date().transform(parseDateString).required("Data de nascimento é obrigatório").test('dataNasc18', 'O titular precisa ter mais de 18 anos', function(value)  {
        const age = differenceInYears(new Date(), value)
        return age >= 18
    }).typeError('Data inválida, formato correto: dd/mm/aaaa'),
    SEXOTIT: yup.string().required("Sexo do titular é obrigatório"),
    NMMAETIT: yup.string().required("Nome da mãe do titular é obrigatório").test(null,'Você precisa inserir o sobrenome', value => {
        const valueArr = value?.split(' ')
        return valueArr?.length > 1 && valueArr[1]?.length > 0 ? true : false
    }),
    CELULAR: yup.string().required("Celular é obrigatório").test(null,'Somente DD 51, 53, 54 ou 55', value => {
        const valueArr = value?.split(' ')
        return valueArr?.length > 1 && valueArr[1]?.length > 0 ? true : false
    }),
    ENVIOSMS: yup.string().required("Obrigatório"),
    EMAIL: yup.string().required("E-mail é obrigatório").email('Precisa ser um e-mail válido'),
    ENVIOEMAIL:  yup.string().required("Obrigatório"),
    NUCLEO: yup.string().required("Número do núcleo é obrigatório").test(null, 'O núcleo deve conter APENAS números, ex: 1, 2, 18', value => {
        return Number.isInteger(parseInt(value))
    }),
    CIDADE: yup.string().required("Cidade é obrigatório"),
    DEPENDENTS: yup.array().of(
        yup.object().shape({
            NMDEP: yup.string().required("Nome do dependente é obrigatório").test(null,'Você precisa inserir o sobrenome', value => {
                const valueArr = value?.split(' ')
                return valueArr?.length > 1 && valueArr[1]?.length > 0 ? true : false
            }),
            CPFDEP: yup.string().required('CPF é obrigatório').matches(/^\d{3}\.\d{3}\.\d{3}\-\d{2}$/, 'CPF incorreto').test(
                'equal',
                'CPF não pode ser igual ao do titular',
                function(value, context) {
                    const [, parent2] = context.from
                    return value !== parent2?.value?.CPFTIT;
                  }
            ),
            DTNASCDEP: yup.date().transform(parseDateString).required("Data de nascimento é obrigatório").typeError('Data inválida, formato correto: dd/mm/aaaa'),
            SEXODEP: yup.string().required("Sexo é obrigatório"),
            PARENTESCODEP: yup.string().required("Parantesco é obrigatório"),
            NMMADDEP: yup.string().required("Nome da mãe é obrigatório").test(null,'Você precisa inserir o sobrenome', value => {
                const valueArr = value?.split(' ')
                return valueArr?.length > 1 && valueArr[1]?.length > 0 ? true : false
            }),
        })
    ),
    CONTRACHEQUE: yup.mixed().required("O contracheque é obrigatório"),
    RENOVACAO: yup.string().required("Indicar se é uma renovação é obrigatório"),
}).required();

function FormFields() {
    const [repetables, setRepeatables] = useState()
    const {  formStructure, handleSubmit: handleSubmitAction } = useContext(CpersContext)
    const { handleSubmit, control, formState: {errors} } = useForm({
        resolver: yupResolver(schema)
      })
    const { attributes: fields } = formStructure

    useEffect(() => {
        let repeatable = {}
        Object.entries(formStructure.attributes).forEach(([key, value], index) => {
            if (value.type === 'repeatable') repeatable = {...repeatable, ...{[key]: value}}
        })
        setRepeatables(repeatable)
    }, [formStructure])

    const removePrincipalConfig = {
        type: 'enumeration',
        enum: [
            'Não',
            'Sim'
        ],
        default: 'Não'
    }

    return (

        <>
            <Form
                name="oficial"
                layout="vertical"
                onFinish={handleSubmit(d => {
                    handleSubmitAction(d)
                })}
            >
                <Row>
                    <Col xs={24} lg={24}>
                        <h2>Dados Pessoais</h2>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col xs={12} lg={7}><FieldSwitch fieldName={'removePrincipal'} fieldValue={removePrincipalConfig} control={control} label={'O titular já possui o convênio?'} /></Col>
                    <Col xs={12} lg={15}>
                        <Alert message=' Utilize esta opção para adicionar mais dependentes ao seu plano. Se você marcar "Sim" o valor do titular não será contabilizado no total da compra.' />
                    </Col>
                </Row>

                <Row gutter={16}>
                    <Col xs={16} lg={7}><FieldSwitch fieldName={'RENOVACAO'} fieldValue={fields['RENOVACAO']} control={control} required error={errors['RENOVACAO']} label={'É uma renovação de um convênio existente?'} /></Col>
                </Row>

                <Row gutter={16}>
                    <Col xs={24} lg={10}><FieldSwitch fieldName={'NMTIT'} fieldValue={fields['NMTIT']} control={control} required error={errors['NMTIT']} /></Col>
                    <Col xs={24} lg={10}><FieldSwitch fieldName={'CPFTIT'} fieldValue={fields['CPFTIT']} control={control} mask={'999.999.999-99'} required error={errors['CPFTIT']} /></Col>
                </Row>
                <Row gutter={16}>
                    <Col xs={24} lg={5}><FieldSwitch fieldName={'DTNASCTIT'} fieldValue={fields['DTNASCTIT']} control={control} required error={errors['DTNASCTIT']} /></Col>
                    <Col xs={24} lg={5}><FieldSwitch fieldName={'SEXOTIT'} fieldValue={fields['SEXOTIT']} control={control} required error={errors['SEXOTIT']} /></Col>
                    <Col xs={24} lg={10}><FieldSwitch fieldName={'NMMAETIT'} fieldValue={fields['NMMAETIT']} control={control} required error={errors['NMMAETIT']} /></Col>
                </Row>
                <Row gutter={16}>
                    <Col xs={24} lg={5}><FieldSwitch fieldName={'CELULAR'} fieldValue={fields['CELULAR']} control={control} mask={'(99) 99999 9999'} required error={errors['CELULAR']} /></Col>
                    <Col xs={24} lg={5}><FieldSwitch fieldName={'ENVIOSMS'} fieldValue={fields['ENVIOSMS']} control={control} required error={errors['ENVIOSMS']} /></Col>
                    <Col xs={24} lg={5}>
                        <FieldSwitch 
                            fieldName={'EMAIL'} 
                            fieldValue={fields['EMAIL']} 
                            control={control}
                            required 
                            error={errors['EMAIL']} 
                        />
                        </Col>
                    <Col xs={24} lg={5}><FieldSwitch fieldName={'ENVIOEMAIL'} fieldValue={fields['ENVIOEMAIL']} control={control} required error={errors['ENVIOEMAIL']} /></Col>
                </Row>
                <Row gutter={16}>
                    <Col xs={24} lg={5}><FieldSwitch fieldName={'NUCLEO'} fieldValue={fields['NUCLEO']} control={control} required error={errors['NUCLEO']} /></Col>
                    <Col xs={24} lg={5}><FieldSwitch fieldName={'CIDADE'} fieldValue={fields['CIDADE']} control={control} required error={errors['CIDADE']}/></Col>
                    <Col xs={24} lg={10}><FieldSwitch fieldName={'CONTRACHEQUE'} fieldValue={fields['CONTRACHEQUE']} control={control} required error={errors['CONTRACHEQUE']}/></Col>
                </Row>


                <Divider />
                {repetables && (
                    Object.entries(repetables).map(([repeatableName, value], index) => {
                        return <Repeatable key={`${index}-${repeatableName}`} repeatableName={repeatableName} repeatableValue={value} control={control} indexRepeatable={index} errors={errors?.DEPENDENTS} index={index} />
                    })
                )}
                <Divider />
                <Row style={{marginTop: 20}}>
                    <Col>
                        <Button htmlType="submit">Enviar</Button>
                    </Col>
                </Row>
            </Form>
        </>
    )
}

export default FormFields