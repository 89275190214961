import { Result } from "antd";

function PaymentSuccess() {
    return (
        <>
                   <Result
                        status="success"
                        title="Forma de pagamento concluida com Sucesso!"
                        subTitle="Não esqueça de pagar o boleto até o vencimento."
                      />
        </>
    )
}

export default PaymentSuccess