import { Alert, Button, Card, Col, Result, Row } from "antd"
import { FileDoneOutlined, QuestionCircleOutlined, BookOutlined, MedicineBoxOutlined } from '@ant-design/icons'
import { Link } from "react-router-dom"

function Home() {
    const offline = process.env.REACT_APP_OFFLINE_MODE
    return (
        <Row gutter={20} style={{margin:20}}>
            <Col>
                <Card>
                    <Result
                        icon={<FileDoneOutlined />}
                        title="Adesão ao plano"
                        extra={<Link to="/cpers/instrucoes"><Button type="primary" size={'large'} shape="round" disabled={offline === "1" ? true : false}>Clique aqui</Button></Link>}
                    />
                    {offline === '1' && (
                        <Alert message="Estamos temporariamente fora do ar para manutenção no sistema" type="error" />
                    )}
                </Card>
            </Col>
            <Col>
                <Card>
                <Result
                    icon={<QuestionCircleOutlined />}
                    title="Perguntas e respostas"
                    extra={<Button type="primary" size={'large'} shape="round" onClick={() => window.location.href = 'https://drive.google.com/file/d/1EjuvdJHAhH2IFRMgP4P29A1-6dvM8WFR/view'}>Clique aqui</Button>}
                />
                </Card>
            </Col>
            <Col>
                <Card>
                <Result
                    icon={<BookOutlined />}
                    title="Manual do associado"
                    extra={<Button type="primary" size={'large'} shape="round" onClick={() => window.location.href = 'https://drive.google.com/file/d/1btnfIp3bxm6KLpU3f_rDL2KhJSolafoE/view'}>Clique aqui</Button>}
                />
                </Card>
            </Col>
            <Col>
                <Card>
                <Result
                    icon={<MedicineBoxOutlined />}
                    title="Encontre seu dentista"
                    extra={<Button type="primary" size={'large'} shape="round" onClick={() => window.location.href = 'https://www.uniodontopoa.com.br/encontre-seu-dentista/'}>Clique aqui</Button>}
                />
                </Card>
            </Col>
        </Row>
    )
}

export default Home