import { Form, Input, InputNumber, Select, Upload, Button, Checkbox } from "antd"
import { Controller } from 'react-hook-form'
import InputMask from 'react-input-mask';
import { UploadOutlined } from '@ant-design/icons';
// import DatePicker from "./DatePicker";

import { labels } from '../utils/enums'

function FieldSwitch({fieldName, fieldValue, control, label, mask, required, error, customRule }) {
    // const dateFormat = 'DD/MM/YYYY';
    const { Option } = Select;

    return fieldValue.type !== 'relation' && fieldValue.type !== 'repeatable' && (
        <Form.Item
            fieldName={fieldName}
            label={labels[fieldName] ?? label}
            name={fieldName}
            required={required}
            validateStatus={error ? 'error' : ''}
            help={error ? error.message : ''}
        >
            {fieldValue.type === 'string' && (
                <Controller
                    control={control}
                    name={fieldName}
                    rules={{ 
                        required: { 
                            value: true, 
                            message: `${labels[fieldName] ?? label} é obrigatório.`
                        }, 
                        validate: {
                            customValidationRule: customRule ? () => customRule() : () => {}
                        }
                    }}
                    render={({ field: { onChange, onBlur, value, ref } }) => {
                        if (mask) {
                            return (
                                <InputMask mask={mask} value={value} onChange={onChange}>
                                    {(inputProps) => <Input {...inputProps} />}
                                </InputMask>
                            )
                        } else {
                            return (
                                <Input 
                                    onChange={onChange}
                                    value={value}
                                />
                            )
                        }
                    }}
                />
                
            )}
            {fieldValue.type === 'integer' && (
                <Controller 
                    control={control}
                    name={fieldName}
                    rules={{ 
                        required: { 
                            value: true, 
                            message: `${labels[fieldName] ?? label} é obrigatório.` 
                        }, 
                        validate: {
                            customValidationRule: customRule ? () => customRule() : () => {}
                        }
                    }}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                        <InputNumber 
                            onChange={onChange}
                            value={value}
                            controls={false}
                        />
                    )}
                />
            )}
            {fieldValue.type === 'date' && (
                <Controller 
                    control={control}
                    name={fieldName}
                    rules={{ 
                        required: { 
                            value: true, 
                            message: `${labels[fieldName] ?? label} é obrigatório.` 
                        }, 
                        validate: {
                            customValidationRule: customRule ? () => customRule() : () => {}
                        }
                    }}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                        <InputMask mask={'99/99/9999'} value={value} onChange={onChange}>
                            {(inputProps) => <Input {...inputProps} />}
                        </InputMask>
                        // <DatePicker 
                        //     onChange={onChange}
                        //     value={value}
                        //     format={dateFormat}
                        //     inputReadOnly={true}
                        //     locale={
                        //         {
                        //             "lang": {
                        //                 "locale": "pt_BR",
                        //                 "placeholder": "Selecione a data",
                        //                 "today": "Hoje",
                        //                 "now": "Agora",
                        //                 "backToToday": "Voltar para hoje",
                        //                 "month": "Mês",
                        //                 "year": "Ano",
                        //                 "dateSelect": "Selecione a data",
                        //                 "monthSelect": "Escolha o mês",
                        //                 "yearSelect": "Escolha o ano",
                        //                 "decadeSelect": "Selecione a década",
                        //                 "yearFormat": "YYYY",
                        //             },
                        //         }
                        //     }
                        // />
                    )}
                />
            )}
            {fieldValue.type === 'decimal' && (
                <Controller 
                    control={control}
                    name={fieldName}
                    rules={{ 
                        required: { 
                            value: true, 
                            message: `${labels[fieldName] ?? label} é obrigatório.` 
                        }, 
                        validate: {
                            customValidationRule: customRule ? () => customRule() : () => {}
                        }
                    }}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                        <InputNumber 
                            onChange={onChange}
                            value={value}
                            controls={false} formatter={fieldValue => `R$ ${fieldValue}`} 
                        />
                    )}
                />
            )}
            {fieldValue.type === 'enumeration' && (

                <Controller 
                    control={control}
                    name={fieldName}
                    rules={{ 
                        required: { 
                            value: true, 
                            message: `${labels[fieldName] ?? label} é obrigatório.` 
                        }, 
                        validate: {
                            customValidationRule: customRule ? () => customRule() : () => {}
                        }
                    }}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                        <Select onChange={onChange} value={value} defaultValue={fieldValue.default ? fieldValue.default : ''}>
                            {fieldValue.enum.map((optionfieldValue, index) => {
                                return <Option key={`${index}#-${optionfieldValue}`} value={optionfieldValue}>{optionfieldValue}</Option>
                            })}
                        </Select>
                    )}
                />

            )}
            {fieldValue.type === 'media' && (
                <Controller 
                    control={control}
                    name={fieldName}
                    rules={{ 
                        required: { 
                            value: true, 
                            message: `${labels[fieldName] ?? label} é obrigatório.` 
                        }, 
                        validate: {
                            customValidationRule: customRule ? () => customRule() : () => {}
                        }
                    }}
                    render={({ field: { onChange, onBlur, value, ref } }) => {
                        const props = {
                            name: 'files',
                            customRequest: function(options) {
                                const { onSuccess, file } = options;
                                onChange(file)
                                onSuccess('ok')
                            },
                            onRemove: function(file) {
                                onChange(undefined)
                            },
                            maxCount: 1
                          };

                          return (
                            <Upload {...props}>
                                <Button icon={<UploadOutlined />}>Clique aqui para enviar o arquivo</Button>
                            </Upload>
                          )
                    }}
                />
            )}
            {fieldValue.type === 'checkbox' && (
                <Controller 
                    control={control}
                    name={fieldName}
                    render={({ field: { onChange, onBlur, value, ref } }) => {


                          return (
                            <Checkbox onChange={onChange}>{}</Checkbox>
                          )
                    }}
                />
            )}
        </Form.Item>
    )
}

export default FieldSwitch