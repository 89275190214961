import { Col, notification, Row, Spin } from 'antd';
import { useState, useEffect, createContext } from 'react';
import api from "../../utils/api";
import FormStructure from './components/formStructure';
import PaymentStep from './components/paymentStep';
import { Route, Routes, useNavigate } from 'react-router-dom';
import Instructions from './components/instructions';
import RegisterSuccess from './components/registerSuccess';

export const CpersContext = createContext();

function Cpers() {
    const [formStructure, setFormStructure] = useState()
    const [loading, setLoading] = useState(false)
    const [registerFormData, setRegisterFormData] = useState()
    // const [registerFormData, setRegisterFormData] = useState({
    //   EMAIL: 'me@lucaskeller.dev',
    //   CPFTIT: '012.026.250-96',
    //   NMTIT: 'Lucas Keller',
    //   DEPENDENTS: [
    //     // {
    //     //   NMDEP: 'Giovenna de Moura',
    //     //   CPFDEP: '123.123.123-87'
    //     // },
    //     // {
    //     //   NMDEP: 'Giovenna de Moura',
    //     //   CPFDEP: '123.123.123-87'
    //     // }
    //   ],
    //   id: 100
    // })
    let navigate = useNavigate()

    useEffect(() => {
      const fetchData = async () => {
        let structure = {}

        const rawFormData = await api.get('/api/content-type-builder/content-types/api::formulario-de-adesao-cpers.formulario-de-adesao-cpers')
        const dependents = await api.get('/api/content-type-builder/content-types/api::dependente-cpers.dependente-cpers')

        structure = rawFormData?.data?.data?.schema
        structure.attributes = { 
          ...structure.attributes, 
          DEPENDENTS: {
            type: 'repeatable',
            displayName: dependents?.data?.data?.schema?.displayName,
            collectionName: dependents?.data?.data?.schema?.collectionName,
            schema: { 
              ...dependents?.data?.data?.schema?.attributes 
            }
          }
        }
        setFormStructure(structure)
      }
        
      fetchData()
        
    }, []);

    const handleSubmit = async (data) => {
      try {
        setLoading(true)

        const formData = new FormData();
        formData.append(`files.CONTRACHEQUE`, data.CONTRACHEQUE, data.CONTRACHEQUE?.name)

        delete data.CONTRACHEQUE

        const postData = {
            ...data,
            ATIVO: 'true'
        }

        formData.append('data', JSON.stringify(postData))

        const strapiReturn = await api.post('/api/formularios-de-adesao-cpers', formData , {
          headers: {
            'Content-Type': 'multipart/form-data;application/json'
          }
        })

        setRegisterFormData({...data, id: strapiReturn?.data?.data?.id})

        setTimeout(() => {
          setLoading(false)
          navigate('sucesso-form-adesao')
        }, 1000);
      } catch (error) {
        setLoading(false)

        console.log('error', error)

        notification['error']({
          message: 'Erro no envio do formulário',
          description:
            'Entre em contato com o suporte técnico da ABC Convênios.',
        });
      }
    }

    return (
      <Spin size='large' tip="Processando, por favor aguarde..." spinning={loading}>
        <CpersContext.Provider 
          value={{
            registerFormData: registerFormData,
            formStructure: formStructure,
            handleSubmit: handleSubmit
          }}
        >
          <div style={{ padding: '16px 24px' }}>
              <Row>
                  <Col lg={24} sm={24}>
                    <Routes>
                      <Route path="instrucoes" element={<Instructions />} />
                      <Route path="formulario-adesao" element={<FormStructure />} />
                      <Route path="sucesso-form-adesao" element={<RegisterSuccess />} />
                      <Route path="pagamento" element={<PaymentStep />} />
                    </Routes>
                  </Col>
              </Row>
          </div>
        </CpersContext.Provider>
      </Spin>
    )
}

export default Cpers