import { Form, Row, Col, Button, Card, Alert } from "antd"
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { useFieldArray } from "react-hook-form";
import FieldSwitch from "../../../../../../../../components/fieldSwitch";
import { labels } from '../../../../../../../../utils/enums'

function Repeatable({ repeatableName, repeatableValue, control, indexRepeatable, errors, index }) {
    const { fields, append, remove } = useFieldArray({
        control: control,
        name: repeatableName
    });
    
    let repeatableModel = {}
    
    Object.entries(repeatableValue?.schema).filter(([, fieldValue]) => fieldValue.type !== 'relation').forEach(([fieldName]) => {
        repeatableModel = {...repeatableModel, ...{[fieldName]: undefined}}
    })
    
    return (
        <>
            <Row>
                <Col xs={24} lg={12}>
                    <h2>{repeatableValue.displayName}</h2>
                </Col>
            </Row>
            {fields.map((item, indexFields) => (
                <Card style={{marginBottom: 20}} key={`${indexFields}-item`}>
                    <Row gutter={16}>
                        <Col xs={24} lg={6}>
                            <FieldSwitch 
                                fieldName={`${repeatableName}.${indexFields}.NMDEP`} fieldValue={repeatableValue.schema['NMDEP']} 
                                control={control} 
                                label={labels['NMDEP']} 
                                required 
                                error={errors && errors[indexFields] && errors[indexFields]['NMDEP']} 
                            />
                        </Col>
                        <Col xs={24} lg={6}>
                            <FieldSwitch 
                                fieldName={`${repeatableName}.${indexFields}.CPFDEP`} 
                                fieldValue={repeatableValue.schema['CPFDEP']} 
                                control={control} 
                                label={labels['CPFDEP']} 
                                mask={'999.999.999-99'}
                                required 
                                error={errors && errors[indexFields] && errors[indexFields]['CPFDEP']} 
                            />
                        </Col>
                        <Col xs={24} lg={7}>
                            <FieldSwitch 
                                fieldName={`${repeatableName}.${indexFields}.DTNASCDEP`} 
                                fieldValue={repeatableValue.schema['DTNASCDEP']} 
                                control={control} 
                                label={labels['DTNASCDEP']} 
                                required 
                                error={errors && errors[indexFields] && errors[indexFields]['DTNASCDEP']} 
                            />
                        </Col>
                        <Col xs={24} lg={5}>
                            <FieldSwitch 
                                fieldName={`${repeatableName}.${indexFields}.SEXODEP`} 
                                fieldValue={repeatableValue.schema['SEXODEP']} 
                                control={control} 
                                label={labels['SEXODEP']} 
                                required 
                                error={errors && errors[indexFields] && errors[indexFields]['SEXODEP']} 
                            />
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col xs={24} lg={6}>
                            <FieldSwitch 
                                fieldName={`${repeatableName}.${indexFields}.PARENTESCODEP`} 
                                fieldValue={repeatableValue.schema['PARENTESCODEP']} 
                                control={control} 
                                label={labels['PARENTESCODEP']} 
                                required 
                                error={errors && errors[indexFields] && errors[indexFields]['PARENTESCODEP']}  
                            />
                        </Col>
                        <Col xs={24} lg={6}>
                            <FieldSwitch 
                                fieldName={`${repeatableName}.${indexFields}.NMMADDEP`}
                                fieldValue={repeatableValue.schema['NMMADDEP']} 
                                control={control} 
                                label={labels['NMMADDEP']} 
                                required 
                                error={errors && errors[indexFields] && errors[indexFields]['NMMADDEP']} 
                            />
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col xs={24} lg={6}>
                            <Button danger onClick={() => remove(indexRepeatable)}>
                                <MinusCircleOutlined /> Remover
                            </Button>
                        </Col>
                    </Row>
                </Card>
            ))}
            <Form.Item>
                <Button type="dashed" onClick={() => append(repeatableModel)} block icon={<PlusOutlined />} disabled={fields.length >= 6}>
                    Adicionar dependente
                </Button>
            </Form.Item>
            {fields.length >= 6 && (
                <Row>
                    <Col>
                        <Alert message="Você pode incluir no máximo 6 dependentes" type="error" />
                    </Col>
                </Row>
            )}
        </>
    )
}

export default Repeatable