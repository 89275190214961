import { Col, Row, Steps } from "antd"

function FormSteps({ step }) {
    return (
        <>
            <Row>
                <Col lg={18} sm={24}>
                    <Steps current={step}>
                        <Steps.Step title="Instruções" />
                        <Steps.Step title="Preenchimento do formulário" />
                        <Steps.Step title="Pagamento" />
                    </Steps>
                </Col>
            </Row>
        </>
    )
}

export default FormSteps