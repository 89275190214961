import { Button, Result } from "antd"
import { RightCircleOutlined } from '@ant-design/icons'
import { useNavigate } from "react-router-dom"

function RegisterSuccess() {
    let navigate = useNavigate()
    return (
        <>
            <Result
                status="success"
                title="Cadastro efetuado com sucesso!"
                subTitle="Agora vamos para a etapa de pagamento, clique no botão abaixo para avançar."
                extra={[
                    <Button type="dashed" key="console" onClick={() => navigate('/cpers/formulario-adesao')}>
                        Voltar
                    </Button>,
                    <Button type="primary" key="console" onClick={() => navigate('/cpers/pagamento')} icon={<RightCircleOutlined />}>
                        IR PARA TELA DE PAGAMENTO
                    </Button>
            ]}
            />
        </>
    )
}

export default RegisterSuccess