import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import ReactGA from "react-ga4";
import * as FullStory from '@fullstory/browser';

if (process.env.NODE_ENV !== 'development') {
  ReactGA.initialize('G-FEG0LY9T08');
  ReactGA.send("pageview");
}

Sentry.init({
  dsn: "https://4bdf632b9e3d4e919fca2c69d1942cb2@o1228052.ingest.sentry.io/6373676",
  integrations: [new BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
  enabled: process.env.NODE_ENV !== 'development',
});

FullStory.init({ orgId: 'o-1E0KXH-na1' });

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
