import React from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";
import './App.css';
import { Divider, Layout } from 'antd';
import Logo from './assets/img/logo-transparent.png'
// import history from './history';
import { PushpinTwoTone, PhoneTwoTone } from '@ant-design/icons';


import Cpers from './pages/cpers';
import Home from './pages/home';
import PaymentSuccess from './pages/paymentSuccess';

const App = () => {
const { Header, Footer, Content } = Layout;
const offline = process.env.REACT_APP_OFFLINE_MODE
return (
<Layout>
  <Header><img src={Logo} title="ABC Convênios" alt='ABC Convênios' /></Header>
  <Content>
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        {offline !== '1' && (<Route path="/cpers/*" element={<Cpers />} />)}
        <Route path="/payment-success" element={<PaymentSuccess />} />
      </Routes>
    </Router>
  </Content>
  <Footer>
    <Divider />
    ABC - Associação de Benefícios e Convênios<br />
    <PushpinTwoTone /> Rua Doutor Flores, 62, sala 1102, bairro Centro Histórico - Porto Alegre - RS - CEP 90020-120<br />
    <PhoneTwoTone /> Telefones: (51) 3024-3090 - (51) 99668-1150 WhatsApp<br />
  </Footer>
</Layout>

)
}


export default App;