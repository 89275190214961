import { Row, Col, Skeleton, PageHeader, Divider } from 'antd';
import FormFields from './components/form';
import { CpersContext } from '../../index'
import { useContext } from 'react';
import FormSteps from '../steps';

function FormStructure() {
    const { formStructure, handleSubmit } = useContext(CpersContext)

    if (!formStructure) {
        return (
            <div style={{ padding: '16px 24px' }}>
                Carregando formulário, um instante...
                <Skeleton active />
            </div>
        )
    } else {
        return (
            <>
                <FormSteps step={1} />
                <Row>
                    <Col lg={24} sm={24}>
                        <PageHeader
                            backIcon={false}
                            title={'Preenchimento do formulário'}
                        />
                        <Divider />
                    </Col>
                </Row>
                
                <Row>
                    <Col lg={24} sm={24}>
                        <FormFields formStructure={formStructure} handleSubmitAction={handleSubmit} />
                    </Col>
                </Row>
            </>
        )   
    }
}

export default FormStructure