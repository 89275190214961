import { Button, Col, Divider, PageHeader, Row } from "antd"
import { useNavigate } from "react-router-dom"
import { RightOutlined } from '@ant-design/icons'
import FormSteps from "../steps"

function Instructions() {
    let navigate = useNavigate()
    return (
        <>
            <FormSteps step={0} />
            <Row>
                <Col lg={24} sm={24}>
                    <PageHeader
                        backIcon={false}
                        title={'Contratação do plano, pagamento e utilização'}
                    />
                    <Divider />
                </Col>
            </Row>
            <Row>
                <Col lg={24} sm={24}>
                    <h3>A) CONTRATAÇÃO DO PLANO E PAGAMENTO:</h3>
                    <p>
                        A contratação do plano Odontológico se dá de forma pré-paga, 
                        através do site <a href="https://www.abcconvenios.com.br">www.abcconvenios.com.br</a> (selecionar CPERS Uniodonto) <strong><u>por um período mínimo de 1
                        (um)ano pelo valor ANUAL de R$ 246,00 por cada pessoa (sem acréscimo em até 4 parcelas). <span style={{ color: 'red' }}>Neste caso o valor da mensalidade é de R$ 20,50 (PLANO ODONTO+TX. ADM) por pessoa.</span></u></strong>
                    </p>
                    <ul>
                        <li>
                            <h4>O plano pode ser pago sem nenhum acréscimo de três formas:</h4>
                            <ul>
                                <li>
                                Pelo Cartão de crédito em até 4 parcelas sem juros (cada parcela de R$ 61,50);
                                </li>
                                <li>
                                Por PIX em <strong>Valor Único</strong> no montante de R$ 246,00, sendo que nesta opção de pagamento o valor da mensalidade é <strong>de R$ 20,50 por pessoa.</strong>
                                </li>
                            </ul>
                        </li>
                        <li>
                            O plano poderá ser pago em mais de 04 parcelas, <strong><u>porém com acréscimo</u></strong>, e somente com cartão de crédito, nos seguintes valores
                            <ul>
                                <li>
                                    5 X 51,17 (com acréscimo) - cada parcela de R$ 21,32 <strong><span style={{ color: 'red' }}>por pessoa.</span></strong>
                                </li>
                                <li>
                                6 X 42,64 (com acréscimo) - cada parcela de R$ 21,32 <strong><span style={{ color: 'red' }}>por pessoa.</span></strong>
                                </li>
                                <li>
                                7 X 36,55 (com acréscimo) - cada parcela de R$ 21,32 <strong><span style={{ color: 'red' }}>por pessoa.</span></strong>
                                </li>
                                <li>
                                8 X 31,98 (com acréscimo) - cada parcela de R$ 21,32 <strong><span style={{ color: 'red' }}>por pessoa.</span></strong>
                                </li>
                                <li>
                                9 X 28,42 (com acréscimo) - cada parcela de R$ 21,32 <strong><span style={{ color: 'red' }}>por pessoa.</span></strong>
                                </li>
                                <li>
                                10 X 25,58 (com acréscimo) - cada parcela de R$ 21,32 <strong><span style={{ color: 'red' }}>por pessoa.</span></strong>
                                </li>
                                <li>
                                11 X 23,26 (com acréscimo) - cada parcela de R$ 21,32 <strong><span style={{ color: 'red' }}>por pessoa.</span></strong>
                                </li>
                                <li>
                                12 X 21,32 (com acréscimo) - cada parcela de R$ 21,32 <strong><span style={{ color: 'red' }}>por pessoa.</span></strong>
                                </li>
                            </ul>
                        </li>
                    </ul>
                    <h3>B) UTILIZAÇÃO:</h3>
                    <p>
                        Para a utilização do plano é necessário que se tenha alguns procedimentos de conferência da condição do associado CPERS, bem como de cumprimento de normas da ANSS(agência Nacional de Saúde Suplementar), para tanto temos como data para inclusão <strong><u>até o dia 20(vinte) de cada mês</u></strong> para que o associado possa marcar sua consulta a partir do primeiro dia útil do mês seguinte. Veja o exemplo a seguir:
                    </p>
                    <p>
                        Exemplo 01: O associado fez a contratação e o pagamento no <strong>dia 12.05.2022</strong>, portanto, a partir do <strong>dia 01.06.2022</strong> poderá agendar a sua consulta.
                    </p>
                    <p>
                        Caso o associado não consiga fazer a sua contratação e o pagamento até o dia 20 do mês em vigência, a sua utilização do Plano se dará no <strong><u>segundo mês subsequente a contratação e o pagamento.</u></strong> Veja o exemplo a seguir:
                    </p>
                    <p>
                        O associado fez a contratação e o pagamento no dia <strong>21.05.2022</strong> o agendamento de consultas somente poderá ocorrer a partir do dia <strong>01.07.2022</strong>.
                    </p>
                    <p>
                    <strong><span style={{ color: 'red' }}>IMPORTANTE: O titular deverá permanecer no plano odontológico pelo período em que o dependente permanecer no plano, inclusive quando o dependente entrar após o titular.</span></strong>
                    </p>
                </Col>
            </Row>
            <br></br>
            <Button type="primary" shape="round" size="large" onClick={() => navigate('/cpers/formulario-adesao')} icon={<RightOutlined />} block>Ir para o formulário de adesão</Button>
        </>
    )
}

export default Instructions