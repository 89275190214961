import { Alert, Button, Card, Col, Descriptions, Divider, Form, Input, Modal, notification, PageHeader, Row, Select } from "antd"
import { useContext, useEffect, useState } from "react";
import { CheckOutlined } from '@ant-design/icons';
import axios from "axios";
import { CpersContext } from '../../index'
import { add, format, parseISO } from 'date-fns'
import api from "../../../../utils/api";
import InputMask from 'react-input-mask';
import { useNavigate } from "react-router-dom"
import ReactGA from 'react-ga4'
import FormSteps from "../steps";

function PaymentStep() {
    const [paymentMethod, setPaymentMethod] = useState()
    const [, setDueData] = useState()
    const [bankSlipMonths, setBankSlipMonths] = useState('no')
    const [loadingPayment, setLoadingPayment] = useState(false)
    const { registerFormData } = useContext(CpersContext)
    const [feesAplied, setFeesAplied] = useState(false)
    const [bankSlipModalOpened, setBankSlipModalOpened] = useState(false)
    const [iuguReturn, setIuguReturn] = useState()

    let navigate = useNavigate()

    const basePrice = 246.00
    const fee = 0.04

    const { Option } = Select
    const [form] = Form.useForm()

    useEffect(() => {
        if (process.env.NODE_ENV !== 'development') {
            ReactGA.event({
                action: "Página de pagamneto",
            });
        }
    }, [])


    function handlePaymentTypeChange(value) {
        if (paymentMethod === 'credit_card' || paymentMethod === 'pix') {
            setDueData(undefined)
        }
        setPaymentMethod(value)
    }

    function handleDueDateChange(value) {
        setDueData(value)
    }

    function handleCreditCardMonthsChange(value) {
        if(parseInt(value) > 4) setFeesAplied(true)
        else setFeesAplied(false)
    }

    function handleBankSlipMonthsChange(value) {
        if(parseInt(value) > 4) setFeesAplied(true)
        else setFeesAplied(false)
        setBankSlipMonths(parseInt(value))
    }

    function getQuantity() {
        const { removePrincipal } = registerFormData ?? {}

        if (removePrincipal === 'Sim') {
            return registerFormData?.DEPENDENTS?.length ? registerFormData?.DEPENDENTS?.length : 0
        } else {
            return registerFormData?.DEPENDENTS?.length ? registerFormData?.DEPENDENTS?.length + 1 : 1
        }        
    }

    const calculateTotalIugu = () => {

        let totalIugu = calculateTotal().toFixed(2) * 100

        if(paymentMethod === 'bank_slip' && bankSlipMonths !== 'no') {
            totalIugu = Math.round(totalIugu / bankSlipMonths)
        }

        return totalIugu
    }

    const renderInvoiceDescription = () => {
        const qty = getQuantity()
        let invoiceDesc = `Plano Uniodonto - ${qty} vida`

        if (qty > 1) {
            invoiceDesc += `s`
        }


        if(paymentMethod === 'bank_slip' && bankSlipMonths !== 'no') {
            invoiceDesc += ` - parcela 1/${bankSlipMonths}`
        }

        return invoiceDesc
    }

    async function sendPayment(paymentData) {
        try {
            if (process.env.NODE_ENV !== 'development') {
                ReactGA.event({
                    action: "Fez o pagamento",
                });
            }

            const apiInvoices = axios.create({
                baseURL: process.env.REACT_APP_INVOICES_API,
            });

            // update user on strapi
            if (paymentData?.payable_with === 'bank_slip') {
                await api.put(`/api/formularios-de-adesao-cpers/${registerFormData?.id}`, {
                    data: {
                        bankSlipDueDate : paymentData?.bankSlipDueDate,
                        bankSlipMonths: paymentData?.bankSlipMonths?.toString(),
                    }
                })   
            }

            const iuguReturnData = await apiInvoices.post('/', {
                    "max_installments_value": paymentData.max_installments_value !== 'no' ? paymentData.max_installments_value : '',
                    "return_url": process.env.REACT_APP_REDIRECT_URL,

                    "notification_url": process.env.REACT_APP_INVOICE_NOTIFICATION_URL,
                    "email": registerFormData?.EMAIL,
                    "due_date": add(new Date(), {days: 2}),
                    "payer": {
                        "cpf_cnpj": registerFormData?.CPFTIT?.replace(/[^a-zA-Z0-9 ]/g, ""),
                        "name": `${registerFormData?.NMTIT} - ${registerFormData?.CPFTIT}`,
                        "email": registerFormData?.EMAIL,
                        "address": {
                            "zip_code": paymentData.zip_code.replace('-',''),
                            "number": paymentData.number,
                            "street": paymentData.street,
                            "district": paymentData.district
                        }
                    },
                    "items": [
                        {
                            "description": renderInvoiceDescription(),
                            "quantity": 1,
                            "price_cents": calculateTotalIugu()
                        }
                    ],
                    "payable_with" : [
                        paymentData.payable_with
                    ],
                    "custom_variables": [
                        {
                            name: 'bank_slip_due_date',
                            value: paymentData.bankSlipDueDate
                        },
                        {
                            name: 'bank_slip_months',
                            value: paymentData.bankSlipMonths
                        },
                        {
                            name: 'clientId',
                            value: registerFormData?.id
                        }
                    ]
                },
                {
                    headers: {
                        'Content-Type': 'application/json'
                    },
                },
            
            )

            if (iuguReturnData?.data?.errors) {
                setLoadingPayment(false)
                throw new Error('Problemas no processamento do pagamento');
            } else {
                setIuguReturn(iuguReturnData)
    
                setTimeout(function() {
                    if (paymentData.payable_with === 'bank_slip') {
                        setBankSlipModalOpened(true)
                    } else {
                        window.location.href = iuguReturnData?.data?.secure_url;
                    }
                }, 4000);                   
            }

        } catch (error) {
            setLoadingPayment(false)
            console.log('error', error)
            notification.error({
                message: 'Problemas no processamento do pagamento',
                description: 'Verifique os dados do titular e dependentes, em caso de dúvidas entre em contato com a ABC Convênios'
            })
        }
    }

    const onFinish = (values) => {
        setLoadingPayment(true)
        sendPayment(values)
    };
    
    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const calculateTotal = () => {
        const { removePrincipal } = registerFormData ?? {}

        let total = removePrincipal === 'Sim' ? 0 : basePrice
        total = getQuantity() * basePrice

        if (feesAplied) total += total * fee

        return total
    }

    const calculateTotalDependents = () => {
        const depLen = registerFormData?.DEPENDENTS?.length
        return depLen > 0 ? basePrice * depLen : '0,00'
    }

    const calculateMonthWFee = ({ total, month }) => {
        const totalWFee = (total + (total * fee)) / month
        return totalWFee.toFixed(2).toString().replace(".", ",")
    }

    const calculateMonthWOFee = ({ total, month }) => {
        const totalWOFee = total / month
        return totalWOFee.toFixed(2).toString().replace(".", ",")
    }

    const calculateMonthPrice = ({month, total}) => {
        return month > 4 ? calculateMonthWFee({ total, month }) : calculateMonthWOFee({ total, month })
    }

    const renderMonths = ({ paymentType }) => {
        const monthsQty = paymentType === 'credit_card' ? 12 : 4
        let options = []

        const total = calculateTotal()

        for (let index = 1; index <= monthsQty; index++) {
            let label = `em ${index}x de R$ ${calculateMonthPrice({ total, month: index })}`
            if (index > 4) {
                label += ' (com acréscimo)'
            }
            options.push({
                value: index,
                label: label
            })
        }

        return options.map((option, index) => {
            return <Option value={option.value}>{option.label}</Option>
        })
    }

    const handleCancelBankSlipModal = () => {
        setBankSlipModalOpened(false)
        setLoadingPayment(false)
    }

    const handleOkBankSlipModal = () => {
        navigate('/payment-success')
    }

    const { removePrincipal } = registerFormData ?? {}

    return (
        <>
            <Modal title="Pagamento por boleto" visible={bankSlipModalOpened} width='50%' onOk={handleOkBankSlipModal} onCancel={handleCancelBankSlipModal}>
                <p>Valor: <strong>{iuguReturn?.data?.total}</strong></p>
                <p>Vencimento: <strong>{iuguReturn && format(parseISO(iuguReturn?.data?.due_date), 'd/M/yyyy')}</strong></p>
                <Card title="Código de barras" extra={<a href={iuguReturn?.data?.secure_url} target="_blank" title="Download de boleto" rel="noreferrer">Download do boleto</a>}>
                    <p style={{textAlign: 'center'}}>
                        <img src={iuguReturn?.data?.bank_slip?.barcode} alt="codigo de barras" />
                    </p>
                    <p style={{textAlign: 'center'}}>

                    <Alert message={iuguReturn?.data?.bank_slip?.digitable_line} type="success" />
                    </p>
                    
                </Card>
            </Modal>
            <FormSteps step={2} />
            <Row>
                <Col lg={24} sm={24}>
                    <PageHeader
                        backIcon={false}
                        title={'Pagamento'}
                    />
                    <Divider />
                </Col>
            </Row>
            <Row>
                <Col xs={24} lg={24}>
                    <h2>Resumo da compra</h2>
                    <p>Escolha abaixo como você efetuará o pagamento, após a escolha, mostraremos um botão para acesso a área segura onde você poderá inserir os dados do seu cartao de crédito, emitir seu boleto ou pagar com o PIX. Logo após o pagamento você será redirecionado de volta par esta página.</p>
                </Col>
            </Row>
            {removePrincipal === 'Sim' && (
                <Row style={{marginBottom: 10}}>
                    <Col>
                        {<Alert message="Você indicou que o titular já possui o convênio, então contabilizaremos somente o valor dos dependentes" type="error" />}
                    </Col>
                </Row>
            )}
            <Row>
                <Col>
                    <Descriptions bordered layout="vertical">
                        <Descriptions.Item label="Titular">R$ {removePrincipal === 'Sim' ? '0,00' : basePrice.toFixed(2).toString().replace(".", ",")}</Descriptions.Item>
                        <Descriptions.Item label={`Dependentes (${registerFormData?.DEPENDENTS?.length ?registerFormData?.DEPENDENTS?.length : 0 })`}>R$ {calculateTotalDependents()}</Descriptions.Item>
                        <Descriptions.Item label="Total"><strong>R$ {calculateTotal().toFixed(2).toString().replace(".", ",")}</strong></Descriptions.Item>
                    </Descriptions>
                </Col>
            </Row>
            <Row  style={{marginTop: 20}}>
                <Col xs={24} lg={24}>
                    <h2>Dados para pagamento</h2>
                    <p>Preencha alguns dados para que possamos processar seu pagamento.</p>
                </Col>
            </Row>
            <Row>
                <Col lg={12} xs={24}>
                    <Card>
                        <Form
                            layout={'vertical'}
                            form={form}
                            name="payment"
                            initialValues={{ 
                                remember: true
                            }}
                            onFinish={onFinish}
                            onFinishFailed={onFinishFailed}
                            autoComplete="off"
                        >
                            <Row gutter={16}>
                                <Col lg={16} xs={24}>
                                    <Form.Item
                                        label="Endereço (Rua, Avenida, etc.)"
                                        name="street"
                                        rules={[{ required: true, message: 'Por favor preencha o endereço' }]}
                                    >
                                     <Input />
                                    </Form.Item>
                                </Col>
                                <Col lg={8} xs={24}>
                                    <Form.Item
                                        label="Número"
                                        name="number"
                                        rules={[{ required: true, message: 'Preencha o número' }]}
                                    >
                                        <Input />
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row gutter={16}>
                                <Col lg={12} xs={24}>
                                <Form.Item
                                    label="Bairro"
                                    name="district"
                                    rules={[{ required: true, message: 'Por favor preencha o bairro' }]}
                                >
                                    <Input />
                                </Form.Item>
                                </Col>
                                <Col lg={12} xs={24}>
                                <Form.Item
                                    label="CEP"
                                    name="zip_code"
                                    rules={[
                                        { required: true, message: 'Por favor preencha o CEP' },
                                        {
                                            pattern: new RegExp(/^[9-9]{1}[0-9]{4}-[0-9]{3}$/),
                                            message: 'CEP incorreto'
                                        }
                                    ]}
                                >
                                    <InputMask mask={'99999-999'}>
                                        {(inputProps) => <Input {...inputProps} />}
                                    </InputMask>
                                </Form.Item>
                                </Col>
                            </Row>
                            <Form.Item 
                                label="Como gostaria de pagar?"
                                name="payable_with"
                                rules={[{ required: true, message: 'Por favor selecione a forma de pagamento' }]}    
                            >
                                <Select onChange={handlePaymentTypeChange}>
                                    <Option value="credit_card">Cartão de crédito</Option>
                                    <Option value="pix">PIX</Option>
                                </Select>
                            </Form.Item>
                            {paymentMethod === 'credit_card' && (
                                <Form.Item 
                                    label="Gostaria de parcelar?"
                                    name="max_installments_value"
                                    rules={[{ required: true, message: 'Por favor preencha o número' }]}
                                    initialValue="no"
                                >
                                    <Select onChange={handleCreditCardMonthsChange}>
                                        <Option value="no">Não</Option>
                                        {renderMonths({ paymentType: 'credit_card' })}
                                    </Select>
                                </Form.Item>
                            )}
                            {paymentMethod === 'bank_slip' && (
                                <Form.Item 
                                    label="Gostaria de parcelar?"
                                    name="bankSlipMonths"
                                    rules={[{ required: true, message: 'Por favor preencha o número' }]}
                                    initialValue="no"
                                >
                                    <Select onChange={handleBankSlipMonthsChange}>
                                        <Option value="no">Não</Option>
                                        {renderMonths({ paymentType: 'bank_slip' })}
                                    </Select>
                                </Form.Item>
                            )}
                            {paymentMethod === 'bank_slip' && bankSlipMonths !== 'no' && (
                                <Form.Item 
                                    label="Qual o melhor dia para vencimento das próximas parcelas?"
                                    name="bankSlipDueDate"
                                    rules={[{ required: true, message: 'Por favor preencha o número' }]} 
                                >
                                    <Select onChange={handleDueDateChange}>
                                        <Option value="15">15</Option>
                                    </Select>
                                </Form.Item>
                            )}
                            <Button type="primary" htmlType="submit" shape="round" icon={<CheckOutlined />} size={'large'} loading={loadingPayment}>
                                Prosseguir com o pagamento
                            </Button>
                            {loadingPayment && (
                                <>
                                    <br />
                                    <br />
                                    <Alert message="Processando a solicitação, em instantes você será direcionado a tela de pagamento." type="success" />
                                </>
                                
                            )}
                        </Form>
                    </Card>
                </Col>
            </Row>
            <Row style={{marginTop: 30}}>
                <Col lg={24} xs={24}>
                    <Descriptions title="Dados do usuario principal" bordered layout="vertical">
                        <Descriptions.Item label="Nome">{registerFormData?.NMTIT}</Descriptions.Item>
                        <Descriptions.Item label="CPF">{registerFormData?.CPFTIT}</Descriptions.Item>
                        <Descriptions.Item label="Celular">{registerFormData?.CELULAR}</Descriptions.Item>
                    </Descriptions>
                    {registerFormData?.DEPENDENTS && registerFormData?.DEPENDENTS.map((depentente, index) => (
                        <Descriptions title="Dependente 01" bordered layout="vertical" key={`${index}-${depentente?.CPFDEP}`}>
                            <Descriptions.Item label="Nome">{depentente?.NMDEP}</Descriptions.Item>
                            <Descriptions.Item label="CPF">{depentente?.CPFDEP}</Descriptions.Item>
                            <Descriptions.Item label="Parentesco">{depentente?.PARENTESCODEP}</Descriptions.Item>
                        </Descriptions>
                    ))}
                </Col>
            </Row>
        </>
    )
}

export default PaymentStep